<template>
  <div v-if="!isLoading" class="roles">
    <div class="box box-default">
      <div class="box-header">
        <div class="flex justify-between">
          <h3 class="box-title">Roles</h3>
          <button class="btn btn-primary btn-sm" @click="openRoleModal()" v-if="isAuthorized('add-role')">Add
            Role</button>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <table class="table table-condensed table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Users</th>
              <th class="action-md"></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="paginatedRoles.length">
              <tr v-for="(role, index) in paginatedRoles" :key="index">
                <td>{{ role.ID }}</td>
                <td>{{ role.Name }}</td>
                <td>
                  <router-link v-if="role.UsersCount" tag="a" :to="{ name: 'users', query: { role: role.ID } }">
                    <label class="label label-primary pointer">{{ role.UsersCount }}</label>
                  </router-link>
                  <label v-else class="label label-primary pointer">{{ role.UsersCount }}</label>
                </td>
                <td class="action-md">
                  <div>
                    <a v-tooltip="{ content: 'View Permissions', trigger: 'click focus hover' }" class="nav-action"
                      @click="openPermissionsModal(role)" v-if="isAuthorized('view-permissions')">
                      <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                        <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                        <path
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z">
                        </path>
                      </svg>
                    </a>
                    <a v-tooltip="{ content: 'Edit Role', trigger: 'click focus hover' }" class="nav-action"
                      @click="openRoleModal(role)" v-if="isAuthorized('update-role')">
                      <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                        <path
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z">
                        </path>
                      </svg>
                    </a>
                    <a v-tooltip="{ content: 'Delete Role', trigger: 'click focus hover' }" class="nav-action danger"
                      v-if="isAuthorized('delete-role')">
                      <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                        <path
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16">
                        </path>
                      </svg>
                    </a>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="4">No role found</td>
              </tr>
            </template>
          </tbody>
        </table>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
    <role-modal></role-modal>
    <permissions-modal></permissions-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RoleModal from "@/components/modals/RoleModal.vue";
import PermissionsModal from "@/components/modals/PermissionsModal.vue";
import Pagination from "@/components/Pagination.vue";
import { PERMISSIONS, SAVE_ROLES, START_LOADING, STOP_LOADING } from "@/store/keys.js";
import { isAuthorized, saveNotification } from "@/lib/utils";

export default {
  name: "Roles",
  components: {
    PermissionsModal,
    RoleModal,
    Pagination,
  },
  data() {
    return {
      limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      currentPage: 1,
      keyword: "",
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "isLoading",
      "roles",
    ]),
    filteredRoles() {
      return this.roles.filter(role => {
        if (this.keyword) {
          const keyword = this.keyword.trim().toLowerCase();
          return role.Name.toLowerCase().contains(keyword);
        }
        return true;
      });
    },
    paginatedRoles() {
      const offset = this.currentPage > 0 ? this.currentPage - 1 : 0;
      if (this.filteredRoles.length > 0) {
        return this.filteredRoles.slice(
          offset * this.limit,
          this.currentPage * this.limit,
        );
      }
      return this.filteredRoles;
    },
    offset() {
      return this.currentPage > 0 ? this.currentPage - 1 : 0;
    },
    meta() {
      return {
        total: this.filteredRoles.length,
        offset: this.offset * this.limit,
        limit: this.limit,
        page: this.currentPage,
      };
    },
  },
  methods: {
    isAuthorized(p) {
      return isAuthorized(p);
    },
    updatePage(page) {
      this.currentPage = page;
    },
    openPermissionsModal(role) {
      this.$bus.$emit("permissions-modal", {
        title: role.Name + " Permissions",
        data: role,
      });
    },
    openRoleModal(role = null) {
      this.$bus.$emit("role-modal", {
        title: role ? "Update Role" : "Create Role",
        data: role,
      });
    },
  },
  mounted() {
    this.$store.commit(START_LOADING);
    this.$store.dispatch(PERMISSIONS).catch(error => {
      saveNotification(error.data.message);
    });
    const apiBaseUrl = process.env.VUE_APP_AUTHORIZATION_API_URL;
    this.$http.get(`${apiBaseUrl}/?Call=ListRoles`, {
      headers: {
        Authorization: "Bearer " + this.jwtToken,
      },
    }).then(response => {
      this.$store.commit(STOP_LOADING);
      if (response.status === 200) {
        this.$store.commit(SAVE_ROLES, response.data.data);
      }
    }).catch(error => {
      this.$store.commit(STOP_LOADING);
      this.$toast.fire("", error.data.message, "error");
      saveNotification(error.data.message);
    });
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
