<template>
  <div @click="clickOverlay()" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       id="bsModalPermissions" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="close()" aria-label="Close" class="close" type="button"><span
            aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="bsModalLabel">{{ title }}</h4>
        </div>
        <div class="modal-body" v-if="role">
          <table class="table table-condensed table-borderless mb-2">
            <tbody>
            <tr>
              <td style="padding-left:0;border-top:0">Name</td>
              <td style="border-top:0">{{ role.Name }}</td>
            </tr>
            </tbody>
          </table>
          <h5>Permissions</h5>
          <div class="row">
            <div v-for="(permission, index) in role.Permissions" :key="index">
              <div class="col-md-3">
                <span class="label label-info">{{ permission.Name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PermissionsModal",
  data() {
    return {
      title: "",
      role: null,
    };
  },
  methods: {
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalPermissions").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("permissions-modal", (args) => {
      this.title = args.title;
      this.role = args.data || null;
      $("#bsModalPermissions").modal("show");
    });
  },
};
</script>

<style scoped></style>
