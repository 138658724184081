<template>
  <div @click="clickOverlay()" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       id="bsModalRole" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="close()" aria-label="Close" class="close" type="button"><span
            aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="bsModalLabel">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider class="form-group" name="name" rules="required|alpha_spaces" tag="div" v-slot="{ errors }">
                <label>Name</label>
                <input @keyup="generateSlug()" aria-label="Name"
                       class="form-control" placeholder="Name" type="text" v-model="form.Name">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider class="form-group" name="slug" rules="required" tag="div" v-slot="{ errors }">
                <label>Slug</label>
                <input aria-label="Slug" class="form-control"
                       placeholder="Slug" readonly type="text" v-model="form.Slug">
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <h5>Permissions</h5>
              <div class="row">
                <div :key="index" class="col-md-4" v-for="(permission, index) in form.Permissions">
                  <label class="flex align-items-center">
                    <input :checked="permission.isChecked" @change="toggle(index)" type="checkbox"/>
                    {{ permission.Name }}
                  </label>
                </div>
              </div>
              <hr/>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Save</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SAVE_ROLES } from "@/store/keys";
import { saveNotification } from "@/lib/utils";

export default {
  name: "RoleModal",
  data() {
    return {
      isLoading: false,
      title: "",
      role: null,
      form: {
        ID: "",
        Name: "",
        Slug: "",
        Permissions: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "permissions",
      "jwtToken",
      "roles",
    ]),
  },
  methods: {
    generateSlug() {
      if (this.form.Name !== "") {
        this.form.Slug = this.form.Name.toLowerCase().trim().replace(/\s+/g, "-");
      } else {
        this.form.Slug = "";
      }
    },
    toggle(index) {
      this.form.Permissions[index].isChecked = !this.form.Permissions[index].isChecked;
    },
    onSubmit: function () {
      this.isLoading = true;
      const payload = JSON.parse(JSON.stringify(this.form));
      payload.Permissions = payload.Permissions.filter(p => p.isChecked);
      payload.Permissions = payload.Permissions.map(p => {
        delete p.isChecked;
        return p;
      });
      payload.Permissions = JSON.stringify(payload.Permissions);
      let url = process.env.VUE_APP_AUTHORIZATION_API_URL;
      let action = "add";
      if (payload.ID !== "") {
        action = "update";
        url += "/?Call=UpdateRole";
      } else {
        url += "/?Call=AddRole";
      }
      this.$http.post(url, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          let message;
          if (action === "update") {
            const role = response.data.data;
            const index = this._.findIndex(this.roles, { ID: role.ID });
            if (index !== -1) {
              role.UsersCount = this.roles[index].UsersCount;
              this.roles.splice(index, 1, role);
            }
            message = "Role updated successfully";
          } else {
            this.roles.push(response.data.data);
            message = "Role created successfully";
          }
          this.$store.commit(SAVE_ROLES, this.roles);
          this.$toast.fire("", message, "success");
          saveNotification(message);
          // Close modal
          this.close();
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalRole").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("role-modal", (args) => {
      this.title = args.title;
      this.role = args.data || null;
      $("#bsModalRole").modal("show");
      if (this.role) {
        let permissions = [...this.permissions];
        permissions = permissions.map(permission => {
          const index = this._.findIndex(this.role.Permissions, { ID: permission.ID });
          return {
            ...permission,
            isChecked: index !== -1,
          };
        });
        this.form = {
          ID: this.role.ID,
          Name: this.role.Name,
          Slug: this.role.Slug,
          Permissions: permissions,
        };
      } else {
        this.form.Permissions = [...this.permissions].map(permission => {
          return {
            ...permission,
            isChecked: false,
          };
        });
      }
    });
  },
};
</script>
